import React from "react";
import styled, { css } from "styled-components";
import { Link } from "gatsby";
import respond from "../styles/abstracts/mediaqueries";

import Seo from "../components/Seo";
import InnerHero from "../components/InnerHero";
import Layout from "../layout/Layout";
import Button from "../components/Button";

import f04Pic from "../images/404-picture.svg";

const Styled404Page = styled.main`
  padding: 6rem 0;
  text-align: center;

  h1 {
    color: var(--color-primary);
    margin: 0 0 2rem 0;
    font-size: 8rem;
  }

  .svg {
    display: block;
    width: 60%;

    ${respond(
      "phone-port",
      css`
        width: 90%;
      `
    )}

    margin-left: 50%;
    transform: translateX(-50%);
  }

  .text-content {
    margin-top: 8rem;

    p {
      margin-bottom: 2rem;
    }
  }

  .btn {
    max-width: 90%;
  }
`;

const Error = () => {
  return (
    <>
      <Seo title="Nicole Tabs | Error" />
      <InnerHero />
      <Styled404Page className="container">
        <img src={f04Pic} alt="404 page illustration" className="svg" />
        <div className="text-content">
          <p>Unfortunately this page does not exist.</p>
          <p>Did you get lost?</p>
          <Button className="btn">
            <Link to="/" className="">
              Go back to the home page
            </Link>
          </Button>
        </div>
      </Styled404Page>
      <Layout innerPage />
    </>
  );
};

export default Error;
