import React, { useContext } from "react";
import styled, { css } from "styled-components";
import { StaticImage } from "gatsby-plugin-image";
import respond from "../styles/abstracts/mediaqueries";

import AppContext from "../context/AppContext";

const StyledInnerHero = styled.header`
  ${respond(
    "phone-port",
    css`
      height: 20vh;
    `
  )}

  .hero-image {
    ${respond(
      "phone-port",
      css`
        height: 100%;
      `
    )}
  }
`;

const InnerHero = () => {
  const { isPhonePort } = useContext(AppContext);

  return (
    <StyledInnerHero>
      {isPhonePort ? (
        <StaticImage
          src="../images/about-hero-mobile.png"
          alt="Nicole saluting a city"
          layout="fullWidth"
          placeholder="blurred"
          className="hero-image"
        />
      ) : (
        <StaticImage
          src="../images/about-hero.jpg"
          alt="Nicole saluting a city"
          layout="fullWidth"
          placeholder="blurred"
          className="hero-image"
        />
      )}
    </StyledInnerHero>
  );
};

export default InnerHero;
